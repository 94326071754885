import React, { useEffect, useState } from 'react';
import { HeadingTag, ModuleBase, Link, Stack, Text, Box, ResponsiveImage } from '@/components';
import { isColorDark } from '@/utils';
import classNames from 'classnames';
// import { useTranslation } from 'react-i18next';
// import Head from 'next/head';

const StatsCenterAlignedModule = ({ data }) => {
  const isDark = isColorDark(data.backgroundColour);
  // const firstGroupStats = data.stats.slice(0, 3);
  // const secondGroupStats = data.stats.slice(3, data.stats.length);
  const [isClient, setIsClient] = useState(false);
  const [firstColumn, setFirstColumn] = useState();
  const [secondColumn, setSecondColumn] = useState();
  // const { t } = useTranslation('common');

  // useEffect(() => {
  //   setIsClient(true);
  // }, []);

  useEffect(() => {
    if (data.stats.length === 3) {
      setFirstColumn(data.stats.slice(0, 2));
      setSecondColumn(data.stats.slice(2, data.stats.length));
    } else if (data.stats.length === 4) {
      setFirstColumn(data.stats.slice(0, 2));
      setSecondColumn(data.stats.slice(2, data.stats.length));
    } else if (data.stats.length === 5) {
      setFirstColumn(data.stats.slice(0, 3));
      setSecondColumn(data.stats.slice(3, data.stats.length));
    } else {
      setFirstColumn(data.stats.slice(0, 3));
      setSecondColumn(data.stats.slice(3, data.stats.length));
    }
  }, []);

  useEffect(() => {
    document.querySelector('#bookingWidgetScript')?.remove();
    const scriptRegex = /<script\b[^>]*>([\s\S]*?)<\/script>/g;
    let match;
    let bookingWidgetInnerScript;
    let bookingWidgetScript;

    const loadWidget = () => {
      while ((match = scriptRegex.exec(data.embedScript)) !== null) {
        bookingWidgetInnerScript = document.createElement('script');
        bookingWidgetInnerScript.async = true;
        bookingWidgetInnerScript.defer = true;
        bookingWidgetInnerScript.id = 'bookingWidgetInnerScript';
        if (!document.querySelector('#bookingWidgetInnerScript') && !match[0].includes('src')) {
          const scriptContent = match[1].trim();
          bookingWidgetInnerScript.innerHTML = scriptContent;
          document.body.appendChild(bookingWidgetInnerScript);
        }
      }
    };
    if (data.embedScript) {
      while ((match = scriptRegex.exec(data.embedScript)) !== null) {
        bookingWidgetScript = document.createElement('script');
        bookingWidgetScript.async = true;
        bookingWidgetScript.defer = true;
        bookingWidgetScript.id = 'bookingWidgetScript';
        if (!document.querySelector('#bookingWidgetScript') && match[0].includes('src')) {
          bookingWidgetScript.src = match[0].match(/src="([^"]+)"/)[1];
          bookingWidgetScript.onload = function () {
            loadWidget();
          };
          document.body.appendChild(bookingWidgetScript);
        }
      }
    }

    setIsClient(true);

    return () => {
      if (typeof bookingWidgetInnerScript !== 'undefined' && bookingWidgetInnerScript.parentNode === document.body) {
        document.body.removeChild(bookingWidgetInnerScript);
      }
      if (typeof bookingWidgetScript !== 'undefined' && bookingWidgetScript.parentNode === document.body) {
        document.body.removeChild(bookingWidgetScript);
      }
    };
  }, [data.embedScript]);

  const openWidget = () => {
    if (data.embedScript) {
      const regex = /id="([^"]+)"/;
      const match = regex.exec(data.embedScript);
      const id = match ? match[1] : null;

      const element = document.getElementById(id);
      if (element) {
        element.click();
      }
    }
  };

  return (
    <ModuleBase data={data} className={classNames(isDark ? 'text-silk' : 'text-charcoal')}>
      {/* <Head>
        <script src="https://www.sevenrooms.com/widget/embed.js"></script>
      </Head> */}
      <Stack className={classNames('container lg:w-[943px]')}>
        {data.logo && (
          <div className="logo relative mx-auto mb-8 h-[80px] w-[71px] md:h-[90px] md:w-[80px] lg:h-[112px] lg:w-[100px] xl:mb-14">
            <ResponsiveImage
              image={data.logo}
              widths={{ xs: 105, md: 120, lg: 150, xl: 150 }}
              imgProps={{ className: 'w-full h-full object-contain pointer-events-none' }}
            />
          </div>
        )}

        <div className="flex flex-col text-center md:mx-auto md:w-[472px] lg:w-full">
          {data.title && (
            <HeadingTag animate data={{ heading: data.title, htag: '' }} className="title-03 mb-7 lg:mb-12" />
          )}
          {data.description && (
            <Text
              dangerouslySetInnerHTML={{ __html: data.description }}
              className="body-02 color-from-bg mb-10 !opacity-75 lg:mb-14"
            ></Text>
          )}
        </div>

        <div className="stats-columns-wrapper w-full md:flex md:gap-x-6 lg:gap-x-8">
          <div className="stats-wrapper-first-column w-full lg:mt-0">
            {firstColumn?.map((stat, statIndex) => (
              <Box
                key={statIndex}
                className={classNames(
                  'flex items-center border-b py-[11px] first:border-t',
                  isDark ? 'border-silk/25' : 'border-charcoal/25',
                )}
              >
                <p className="stat-label color-from-bg label-01 mr-2 w-28 shrink-0 opacity-50 md:w-[100px] lg:w-[162px]">
                  {stat.statLabel}
                </p>
                <div
                  className="stat-detail body-03 w-full text-right md:text-left [&_a]:underline"
                  dangerouslySetInnerHTML={{ __html: stat.statValue }}
                >
                  {/* {stat.statValue} */}
                </div>
              </Box>
            ))}
          </div>

          <div className="stats-wrapper-second-column w-full">
            {secondColumn?.map((stat, statIndex) => (
              <Box
                key={statIndex}
                className={classNames(
                  'flex items-center border-b py-[11px] first:md:border-t',
                  isDark ? 'border-silk/25' : 'border-charcoal/25',
                )}
              >
                <p className="stat-label color-from-bg label-01 mr-2 w-28 shrink-0 opacity-50 md:w-[100px] lg:w-[162px]">
                  {stat.statLabel}
                </p>
                <p
                  className="stat-detail body-03 w-full text-right md:text-left [&_a]:underline"
                  dangerouslySetInnerHTML={{ __html: stat.statValue }}
                >
                  {/* {stat.statValue} */}
                </p>
              </Box>
            ))}
          </div>
        </div>

        {(data.primaryCTA || data.secondaryCTA || data.embedScript) && (
          <div className="mt-10 flex flex-col items-center gap-2 md:mt-10 md:flex-row md:justify-center lg:mt-16 lg:items-center">
            {data.primaryCTA && !data.embedScript && (
              <Link animate className={classNames('btn primary', isDark ? 'dark' : 'light')} link={data.primaryCTA} />
            )}

            {isClient && data.embedScript && (
              <Box>
                <div className={classNames('btn primary', isDark ? 'dark' : 'light')} onClick={openWidget}>
                  <div dangerouslySetInnerHTML={{ __html: data.embedScript }} />
                </div>
              </Box>
            )}
            <Link animate className={classNames('btn secondary', isDark ? 'dark' : 'light')} link={data.secondaryCTA} />
            <Link animate className={classNames('btn secondary', isDark ? 'dark' : 'light')} link={data.terciaryCTA} />
          </div>
        )}
      </Stack>
    </ModuleBase>
  );
};

export default StatsCenterAlignedModule;
