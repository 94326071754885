import PropTypes from 'prop-types';
import { ResponsiveImage, Link, MediaLightboxCarousel } from '..';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import tailwindConfig from '@/tailwind.config.js';
import CarouselIndicator from '@/assets/carouselIndicator.svg';
import { MotionContext } from 'framer-motion';

const GenericCardLarge = ({ data, className, isDragging, isListItems, ...props }) => {
  const { t } = useTranslation('common');
  const [desktop, setDesktop] = useState(false);
  const [activeImage, setActiveImage] = useState(null);

  const images = [];
  data?.images?.map((image) => images.push(image));

  useEffect(() => {
    const resize = () => {
      setDesktop(window.innerWidth >= parseInt(tailwindConfig.theme.screens.xl, 10));
    };

    resize();

    window.addEventListener('resize', resize);

    return () => window.removeEventListener('resize', resize);
  }, []);

  return (
    <div className={classNames('card-wrapper', isListItems ? 'h-full' : '')}>
      {activeImage !== null && (
        <MotionContext.Provider value={{}}>
          <MediaLightboxCarousel
            moduleId={data.moduleId}
            slides={images}
            activeImage={activeImage}
            onClose={() => setActiveImage(null)}
          />
        </MotionContext.Provider>
      )}
      <>
        {data.link && desktop ? (
          <Link
            className={classNames(
              className,
              'main-wrapper group flex flex-col',
              isListItems ? 'h-full' : 'h-[570px] md:h-[578px] lg:h-[730px] xxl:h-[730px]',
            )}
            {...props}
            link={data.link}
            onPointerDownCapture={(e) => e.preventDefault()}
            onClick={(e) => isDragging && e.preventDefault()}
          >
            <div className="relative aspect-[8/10] h-[429px] w-full overflow-hidden md:h-[435px] lg:aspect-[unset] lg:h-[600px]">
              {data.image && (
                <ResponsiveImage
                  image={data.image}
                  widths={{ xs: 645, md: 534, lg: 708 }}
                  heights={{ xs: 644, md: 653, lg: 1065 }}
                  imgProps={{ className: 'transition-transform duration-1000 group-hover:scale-110' }}
                />
              )}

              {data?.mediaLightBox?.length > 0 && (
                <div
                  className="absolute right-8 top-4 z-50 flex h-6 w-[57px] cursor-pointer items-center justify-center rounded-[1000px] border border-silk"
                  onClick={(e) => {
                    e.preventDefault();
                    setActiveImage(0);
                  }}
                >
                  <CarouselIndicator className={classNames('ltr:mr-2 rtl:ml-2')} />
                  <span className="font-montserrat text-[12px] font-[500] leading-3 text-silk">
                    {data.mediaLightBox.length}
                  </span>
                </div>
              )}
            </div>

            <div
              className={classNames(
                'content-wrapper flex grow flex-col justify-between pb-4 lg:pb-0',
                isListItems ? '' : 'lg:h-[90px]',
              )}
            >
              <div className={classNames('text-wrapper flex flex-col justify-end')}>
                <div className={classNames('flex flex-col justify-between', isListItems ? 'mt-4' : '')}>
                  {data.label && (
                    <p className={classNames('label-01 color-from-bg mb-3 opacity-50', isListItems ? '' : 'mt-5')}>
                      {data.label}
                    </p>
                  )}
                  {data.title && (
                    <p
                      className={classNames(
                        'title-05 color-from-bg mb-2 xl:text-[24px] xl:leading-8',
                        !data.label ? (isListItems ? 'mt-8' : 'mt-8 lg:mt-0') : 'mt-0',
                      )}
                      role="heading"
                      aria-level="7"
                    >
                      {data.title}
                    </p>
                  )}
                </div>

                {data.description && (
                  <div
                    className={classNames(
                      'body-04 lg:body-03 color-from-bg leading-5 tracking-[0.14px] opacity-75 xl:text-[14px]',
                      !isListItems
                        ? data.link
                          ? 'line-clamp-1 lg:!line-clamp-2'
                          : 'line-clamp-2 lg:!line-clamp-2'
                        : '',
                    )}
                    dangerouslySetInnerHTML={{ __html: data.description }}
                  />
                )}

                {data.link && (
                  <div className="xl:hidden">
                    <Link className="btn tertiary color-from-bg inline pb-0" link={data.link}>
                      {t('general.$viewMore')}
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </Link>
        ) : (
          <div
            className={classNames(
              className,
              'main-wrapper flex flex-col',
              isListItems ? 'h-full' : 'h-[570px] md:h-[578px] lg:h-[730px] xxl:h-[730px]',
            )}
          >
            <div className="relative aspect-[8/10] h-[429px] w-full overflow-hidden md:h-[435px] lg:aspect-[unset] lg:h-[600px]">
              {data.image && (
                <ResponsiveImage
                  image={data.image}
                  widths={{ xs: 645, md: 534, lg: 708 }}
                  heights={{ xs: 644, md: 653, lg: 1065 }}
                />
              )}

              {data?.mediaLightBox?.length > 0 && (
                <div
                  className="absolute right-8 top-4 z-50 flex h-6 w-[57px] cursor-pointer items-center justify-center rounded-[1000px] border border-silk"
                  onClick={(e) => {
                    e.preventDefault();
                    setActiveImage(0);
                  }}
                >
                  <CarouselIndicator className={classNames('ltr:mr-2 rtl:ml-2')} />
                  <span className="font-montserrat text-[12px] font-[500] leading-3 text-silk">
                    {data.mediaLightBox.length}
                  </span>
                </div>
              )}
            </div>

            <div
              className={classNames(
                'content-wrapper flex grow flex-col justify-between pb-4 lg:pb-0 lg:pt-0',
                data.link ? '-mb-2' : 'mb-0',
                isListItems ? '' : 'lg:h-[90px]',
              )}
            >
              <div className={classNames('text-wrapper flex grow flex-col justify-end')}>
                <div className="mt-4 flex flex-col justify-between">
                  {data.label && <p className="label-01 color-from-bg mb-3 opacity-50">{data.label}</p>}
                  {data.title && (
                    <p
                      className={classNames(
                        'title-05 color-from-bg mb-2 xl:text-[24px] xl:leading-8',
                        !data.label ? (isListItems ? 'mt-8' : 'mt-8 lg:mt-0') : 'mt-0',
                      )}
                      role="heading"
                      aria-level="7"
                    >
                      {data.title}
                    </p>
                  )}
                </div>

                {data.description && (
                  <div
                    className={classNames(
                      'body-04 lg:body-03 color-from-bg leading-5 tracking-[0.14px] opacity-75 xl:text-[14px]',
                      !isListItems
                        ? data.link
                          ? 'line-clamp-1 xl:!line-clamp-2'
                          : 'line-clamp-2 lg:!line-clamp-2'
                        : 'grow',
                    )}
                    dangerouslySetInnerHTML={{ __html: data.description }}
                  />
                )}

                {data.link && (
                  <div className="mt-1 xl:hidden">
                    <Link className="btn tertiary color-from-bg inline pb-0" link={data.link}>
                      {t('general.$viewMore')}
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </>
    </div>
  );
};
GenericCardLarge.propTypes = {
  data: PropTypes.object.isRequired,
};

export default GenericCardLarge;
