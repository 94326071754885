import Custom500 from './500';
import { Metadata, PageContainer, CMSModule } from '@/components';
import Enquiry from '@/components/enquiry/Enquiry';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import nextI18nConfig from '../next-i18next.config';
import { setPageId, setRobot, wrapper, setLanguages } from '@/store/index';
import Header from '@/modules/Header';
import { getHost, getProtocol } from '@/utils';
// import Script from 'next/script';

const Index = ({ errorCode, errorMessage, metadata, moduleContent, footer, header, enquiryPopup }) => {
  if (errorCode) {
    return <Custom500 errorCode={errorCode} errorMessage={errorMessage} />;
  }

  const backgroundColour = moduleContent?.map((item) => {
    return item.props.backgroundColour;
  });

  const checkModuleName = moduleContent?.map((item) => {
    return item.moduleName;
  });

  return (
    <>
      <Metadata metadata={metadata} />
      {header && (
        <Header header={header} metadata={metadata} backgroundColour={backgroundColour} moduleName={checkModuleName} />
      )}
      <PageContainer>
        {moduleContent?.map((module) => (
          <CMSModule key={module.moduleId} module={module} />
        ))}

        {footer && <CMSModule module={footer} />}
      </PageContainer>
      <Enquiry data={enquiryPopup} key={metadata?.pageUrl} />
      {/* <Script
        src="https://sdk.selfbook.com/selfbook.js?hotelId=9403&apiKey=0xoQGFM9rk5AT2UP-lDc9-rFKUKEsyTDBaM"
        id="selfbook_jssdk"
        defer
      /> */}
    </>
  );
};

export default Index;

export const getServerSideProps = wrapper.getServerSideProps((store) => async (context) => {
  const { resolvedUrl, locale, defaultLocale, res, req } = context;
  const host = getHost(req);
  const protocol = getProtocol(req);

  // workaround for missing assets spamming the API
  if (resolvedUrl.startsWith('/_next/')) {
    res.statusCode = 404;
    res.end();
    return;
  }

  let errorCode,
    errorMessage = null;

  const { cookies } = req;

  try {
    const fetchUrl = new URL(`${protocol}//${host}/api${defaultLocale !== locale ? '/' + locale : ''}${resolvedUrl}`);
    fetchUrl.pathname = fetchUrl.pathname.endsWith('/') ? fetchUrl.pathname.slice(0, -1) : fetchUrl.pathname;

    const dataRes = await fetch(fetchUrl.href, {
      headers: { membership: cookies.membership },
      keepalive: true,
      redirect: 'manual',
    });

    errorCode = dataRes?.ok ? false : dataRes?.status;

    const contentType = dataRes?.headers.get('content-type');

    if (errorCode === 500) {
      errorMessage =
        process.env.NODE_ENV === 'development'
          ? 'BE Internal Server Error'
          : "An error has occurred and we're working to fix the problem.";
      res.statusCode = 500;
    } else if (!contentType || contentType.indexOf('application/json') === -1) {
      if (errorCode === 404) {
        errorMessage = 'Page not found';
        res.statusCode = 404;
      } else if (errorCode >= 300 && errorCode < 400) {
        console.warn('Redirecting to:', dataRes.headers.get('Location'));
        res.writeHead(errorCode, {
          Location: dataRes.headers.get('Location'),
        });
        res.end();
      } else {
        errorCode = 500;
        errorMessage =
          process.env.NODE_ENV === 'development'
            ? 'BE response not JSON Object'
            : "An error has occurred and we're working to fix the problem.";
        res.statusCode = 500;
      }
    } else if (!errorCode || errorCode === 404) {
      const data = await dataRes.json();

      if (data?.metadata?.pageType === 'sp404PageNotFound' && res) {
        res.statusCode = 404;
      }
      const isRobot = !!req.headers['is-robot'];
      store.dispatch(setRobot(isRobot));

      const isMembershipPage = data.moduleContent.find((module) => module.moduleName === 'MediaAssetsLibraryModule');

      res.setHeader(
        'Cache-Control',
        `public, max-age=${resolvedUrl.includes('isPreview=true') || isMembershipPage ? 0 : process.env.CDN_CACHE_MAX_AGE || 7200}`,
      );

      if (data?.metadata) {
        store.dispatch(setPageId(data.metadata.pageId));
      }
      if (data?.metadata) {
        store.dispatch(setLanguages(data.metadata.languages));
      }

      const translations = await serverSideTranslations(locale, ['common'], nextI18nConfig);

      return {
        props: {
          ...data,
          ...translations,
        },
      };
    }
  } catch (error) {
    errorCode = 500;
    console.error(error);
    errorMessage = error.message;
    res.statusCode = 500;
  }

  return {
    props: { errorCode, errorMessage },
  };
});
