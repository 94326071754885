import React from 'react';
import { HeadingTag, ModuleBase, Link, Stack, Text, Box } from '@/components';
import { isColorDark } from '@/utils';
import classNames from 'classnames';

const StatsLeftAlignedModule = ({ data }) => {
  const isDark = isColorDark(data.backgroundColour);
  return (
    <ModuleBase data={data} className={classNames(isDark ? 'text-silk' : 'text-charcoal')}>
      <Stack
        className={classNames('container flex flex-col items-center justify-center lg:flex-row lg:justify-between')}
      >
        <div className="description-wrapper lg:w-[456px]">
          <div className="flex flex-col">
            {/* <pre>{JSON.stringify(data.title, null, 2)}</pre> */}
            {data.title && (
              <HeadingTag animate data={{ heading: data.title, htag: '' }} className="title-04 mb-6 lg:mb-8" />
            )}
            {data.description && (
              <Text
                dangerouslySetInnerHTML={{ __html: data.description }}
                className="body-03 color-from-bg mb-10 !opacity-75"
              ></Text>
            )}
          </div>
          {(data.primaryCTA || data.secondaryCTA) && (
            <div className="flex flex-col gap-2 has-[a:empty]:gap-0 md:flex-row lg:items-center [&_a:empty]:hidden">
              <Link animate className={classNames('btn primary', isDark ? 'dark' : 'light')} link={data.primaryCTA} />
              <Link
                animate
                className={classNames('btn secondary', isDark ? 'dark' : 'light')}
                link={data.secondaryCTA}
              />
              <Link
                animate
                className={classNames('btn secondary', isDark ? 'dark' : 'light')}
                link={data.terciaryCTA}
              />
            </div>
          )}
        </div>

        <div className="stats-wrapper mt-8 w-full lg:mt-0 lg:w-[456px]">
          {data.stats.map((stat, statIndex) => (
            <Box
              key={statIndex}
              className={classNames(
                'flex items-center border-b py-4 first:border-t',
                isDark ? 'border-silk/25' : 'border-charcoal/25',
              )}
            >
              <p className="stat-label label-01 mr-2 w-28 shrink-0 opacity-50 md:w-[162px]">{stat.statLabel}</p>
              <div className="stat-detail body-03 w-full" dangerouslySetInnerHTML={{ __html: stat.statValue }} />
            </Box>
          ))}
        </div>
      </Stack>
    </ModuleBase>
  );
};

export default StatsLeftAlignedModule;
